import logo from './assets/img/logo_v1.png';
import './App.css';
// BOOTSTRAP
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="swing" alt="logo" />
         
            <h1 className='App-Soon'>
            Coming Soon
            </h1>
          
       
        
      </header>
    </div>
  );
}

export default App;
